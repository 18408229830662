import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import intersect from '@alpinejs/intersect';
import focus from '@alpinejs/focus';
import morph from '@alpinejs/morph';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import htmx from 'htmx.org';

window.Alpine = Alpine;
window.Swiper = Swiper;
Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(focus);
Alpine.plugin(morph);


window.htmx = htmx;

htmx.defineExtension('alpine-morph', {
    isInlineSwap: function (swapStyle) {
        return swapStyle === 'morph';
    },
    handleSwap: function (swapStyle, target, fragment) {
        if (swapStyle === 'morph') {
            if (fragment.nodeType === Node.DOCUMENT_FRAGMENT_NODE) {
                Alpine.morph(target, fragment.firstElementChild);
                return [target];
            } else {
                Alpine.morph(target, fragment.outerHTML);
                return [target];
            }
        }
    }
});